const Item = ({ indicator, name }) => {
  return (
    <div className='text-center p-4'>
      <div className='text-[#d00000] text-3xl font-extrabold font-["Anton"] tracking-wide mb-2 sm:text-5xl'>
        {indicator}
      </div>
      <div className='leading-none font-semibold sm:text-xl'>
        {name.map((n, i) => (
          <div key={i}>{n}</div>
        ))}
      </div>
    </div>
  );
};

const AboutNumberSection = () => {
  return (
    <section>
      <div className='p-4 bg-[#626c39] sm:p-8'>
        <div className='mb-4'>
          <h1 className='mb-2 text-3xl font-["Anton"] text-[#e5eccc]'>
            Build lasting healthy habit with the team TheChamps
          </h1>
          <p className='text-[#e5eccc]'>
            We never let anything stand between you and your fitness goals.
          </p>
        </div>
        <div className='bg-white rounded-xl py-8 space-y-6'>
          <Item
            indicator='500+'
            name={['Transformation', 'completed']}
          />
          <Item
            indicator='1.5M+'
            name={['Total', 'community']}
          />
          <Item
            indicator='4.8+'
            name={['Average', 'rating out of 5']}
          />
        </div>
      </div>
    </section>
  );
};

export default AboutNumberSection;
