import React from 'react';

const SmartPhone = ({ children, height = 667, width = 360 }) => {
  return (
    <div
      className={`border-black relative shrink-0 overflow-hidden`}
      style={{
        height,
        width,
        borderWidth: height / 42,
        borderBottomWidth: height / 28,
        borderRadius: width / 7.5,
      }}
    >
      {children}
      <div
        className='absolute top-[-1px] border-black left-1/2 translate-x-[-50%] rounded-b-3xl bg-black flex justify-center items-center'
        style={{
          width: width / 2,
          borderWidth: height / 40,
        }}
      >
        <div
          className='border border-gray-500'
          style={{
            width: width / 6,
          }}
        ></div>
        <div
          className='absolute size-1 rounded-full bg-gray-500'
          style={{
            right: width / 16,
          }}
        ></div>
      </div>
    </div>
  );
};

export default SmartPhone;
