import tiktokIcon from '../assets/icons/tiktok.png';
import instagramIcon from '../assets/icons/instagram.png';
import youtubeIcon from '../assets/icons/youtube.png';
import facebookIcon from '../assets/icons/facebook.png';

const Footer = () => {
  return (
    <section>
      <div className='pt-16 bg-[#626c39]'>
        <div className='flex justify-center items-center gap-6'>
          <a
            className='flex items-center gap-1'
            href='https://www.youtube.com/@DavidParkfit'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              src={youtubeIcon}
              alt='youtube logo'
              width={36}
            />
          </a>
          <a
            className='flex items-center gap-1'
            href='https://www.instagram.com/davidpark_._'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              src={instagramIcon}
              alt='instagram logo'
              width={36}
            />
          </a>
          <a
            className='flex items-center gap-1'
            href='https://web.facebook.com/profile.php?id=61565783630537'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              src={facebookIcon}
              alt='facebook logo'
              width={36}
            />
          </a>
          <a
            className='flex items-center gap-1'
            href='https://www.tiktok.com/@davidpark_._'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              src={tiktokIcon}
              alt='tiktok logo'
              width={36}
            />
          </a>
        </div>
        <div className='flex justify-center items-center text-white pt-4 pb-16'>
          <a
            className='mr-1'
            href='https://dx2project.com'
            target='_blank'
            rel='noopener noreferrer'
          >
            Dx2Project
          </a>{' '}
          ©️ All rights reserved, 2024
        </div>
      </div>
    </section>
  );
};

export default Footer;
