const Item = ({ indicator, name }) => {
  return (
    <div className='text-center p-4 bg-white rounded-xl py-8'>
      <div className='text-[#d00000] text-3xl font-extrabold font-["Anton"] tracking-wide mb-2 sm:text-5xl'>
        {indicator}
      </div>
      <div className='leading-none font-semibold sm:text-xl'>
        {name.map((n, i) => (
          <div key={i}>{n}</div>
        ))}
      </div>
    </div>
  );
};

const AboutPTSection = () => {
  return (
    <section>
      <div className='p-4 bg-[#626c39] sm:p-8'>
        <div className='mb-4'>
          <h1 className='mb-2 text-3xl font-["Anton"] text-[#e5eccc]'>
            Get David as your PT
          </h1>
          <p className='text-[#e5eccc]'>
            With over a 10 years of training experience, he’s developed a
            workout style focused on building sustainable habits and effective
            routines that blend effortlessly with everyday life.
          </p>
        </div>
        <div className='space-y-4'>
          {/* <Item
            indicator='AI POWERED'
            name={['Quick and easy', 'AI meal tracking']}
          /> */}
          <Item
            indicator='15-50MIN'
            name={['Workout that fit', 'into your lifestyle']}
          />
          <Item
            indicator='24/7'
            name={['Direct contact', 'for questions']}
          />
          <Item
            indicator='100%'
            name={['Result', 'guaranteed!']}
          />
        </div>
      </div>
    </section>
  );
};

export default AboutPTSection;
