import { useRef, useState } from 'react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import program9daysImg from '../assets/imgs/9days_program.png';
import program9weeksImg from '../assets/imgs/9weeks_program.png';
import program9daysImg1 from '../assets/programs/9days/1.png';
import program9daysImg2 from '../assets/programs/9days/2.png';
import program9daysImg3 from '../assets/programs/9days/3.png';
import program9daysImg4 from '../assets/programs/9days/4.png';
import program9daysImg5 from '../assets/programs/9days/5.png';
import program9daysImg6 from '../assets/programs/9days/6.png';
import program9daysImg7 from '../assets/programs/9days/7.png';
import program9daysImg8 from '../assets/programs/9days/8.png';
import program9daysImg9 from '../assets/programs/9days/9.png';
import program9daysImg10 from '../assets/programs/9days/10.png';
import program9daysImg11 from '../assets/programs/9days/11.png';

const programs9days = [
  program9daysImg1,
  program9daysImg2,
  program9daysImg3,
  program9daysImg4,
  program9daysImg5,
  program9daysImg6,
  program9daysImg7,
  program9daysImg8,
  program9daysImg9,
  program9daysImg10,
  program9daysImg11,
];

const products = [
  {
    title: '9DAYS BUILD HABITS',
    price: '$9',
    priceDeco: '[$1/Day]',
    description:
      'Everyone has the potential to reach their fitness goals. The main hurdle was not the workout routine, lack of time, money, motivation, or knowledge. It was the “OLD ROUTINE” that kept holding them back. 9DAYS offers a new solution for you to incorporate a fitness habit into your lifestyle.',
    link: 'https://buymeacoffee.com/davidpark/e/159590',
    img: program9daysImg,
  },
  {
    title: '9-WEEK TRANSFORMATION',
    price: '$30',
    priceDeco: '[$0.5/Day]',
    description:
      'Growing muscle mass with home training must be approached differently than working out at the gym. All routines are created for people who are willing to build their dream physiques through home training, and this program will take you to the next level.',
    link: 'https://buymeacoffee.com/davidpark/e/240945',
    img: program9weeksImg,
  },
];

const ProgramSection = () => {
  const dialogRef = useRef(null);
  const horizontalRef = useRef(null);

  const [selectedProgram, setSelectedProgram] = useState(null);

  const closeDialog = () => {
    dialogRef.current.close();
    document.body.classList.remove('overflow-hidden');
  };

  const openDialog = () => {
    dialogRef.current.showModal();
    document.body.classList.add('overflow-hidden');
  };

  const handleSelectProgram = (program) => () => {
    setSelectedProgram(program);
    openDialog();
  };

  const handleNext = () => {
    horizontalRef.current.scrollBy({ left: 320, behavior: 'smooth' });
  };

  const handlePrev = () => {
    horizontalRef.current.scrollBy({ left: -320, behavior: 'smooth' });
  };

  return (
    <section id='program'>
      <div className='bg-[#e5eccc] pb-4'>
        <h1 className='mb-4 text-3xl font-["Anton"] text-[#626c39] px-6 pt-4'>
          Other Programs
        </h1>
        <div className='relative py-2'>
          <div
            ref={horizontalRef}
            className='flex gap-4 snap-x overflow-x-scroll scrollbar-hidden snap-mandatory border-t border-b border-[#626c39] w-full'
          >
            <div className='snap-center shrink-0 w-1/5'></div>
            {products.map((product, i) => (
              <div
                key={i}
                className='snap-always snap-center shrink-0 w-2/3 flex flex-col items-center py-4 sm:w-1/2'
                onClick={
                  i === 0
                    ? handleSelectProgram(product)
                    : () => {
                        window.open(product.link, '_blank');
                      }
                }
              >
                <img
                  src={product.img}
                  alt={product.title}
                  className='size-72 object-cover'
                />
                <h3 className='font-bold'>{product.title}</h3>
              </div>
            ))}
            <div className='snap-center shrink-0 w-1/5'></div>
          </div>
          <div className='absolute top-0 left-0 h-full w-[10%] bg-gradient-to-r from-[#e5eccc]'></div>
          <div className='absolute top-0 right-0 h-full w-[10%] bg-gradient-to-l from-[#e5eccc]'></div>
          <div className='absolute top-1/2 text-[#626c39]'>
            <ChevronLeftIcon
              className='size-7'
              onClick={handlePrev}
            />
          </div>
          <div className='absolute top-1/2 right-0 text-[#626c39]'>
            <ChevronRightIcon
              className='size-7'
              onClick={handleNext}
            />
          </div>
        </div>
      </div>
      <dialog
        ref={dialogRef}
        className='outline-none max-w-xl h-2/3 rounded-xl'
        onCancel={() => document.body.classList.remove('overflow-hidden')}
      >
        <div className='flex flex-col p-4'>
          <div className='self-end fixed z-10'>
            <XCircleIcon
              className='size-5'
              onClick={closeDialog}
            />
          </div>
          <div className='flex justify-center items-center mb-4'>
            {selectedProgram?.title === '9DAYS BUILD HABITS' && (
              <div className='flex flex-col items-center'>
                {programs9days.map((program, i) => (
                  <div
                    key={i}
                    className='relative'
                  >
                    <img
                      src={program}
                      alt={selectedProgram.title}
                      className='object-cover'
                    />
                    {i === 9 && (
                      <div className='absolute bottom-[8%] font-bold w-full px-[15%]'>
                        <button
                          className='uppercase rounded-full px-3 py-2 bg-[#e5eccc] tracking-wide text-[#0b3d01]'
                          onClick={() => {
                            window.open(selectedProgram.link, '_blank');
                          }}
                        >
                          start now
                        </button>
                      </div>
                    )}
                    {i === 10 && (
                      <div className='absolute bottom-[8%] w-full text-[#0b3d01] text-center'>
                        <div className='mb-2'>get started today</div>
                        <button
                          className='uppercase rounded-full px-3 py-2 bg-[#e5eccc] tracking-wide font-bold'
                          onClick={() => {
                            window.open(selectedProgram.link, '_blank');
                          }}
                        >
                          start now
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div>
            <div className='text-xs font-bold mb-2'>More programs</div>
            <div className='relative'>
              <div className='flex gap-4 overflow-x-scroll scrollbar-hidden border-t border-b border-[#626c39]'>
                <div className='snap-center shrink-0 w-1/6'></div>
                {products.map((product, i) => (
                  <div
                    key={i}
                    className='shrink-0 flex flex-col items-center'
                    onClick={
                      i === 0
                        ? handleSelectProgram(product)
                        : () => {
                            window.open(product.link, '_blank');
                          }
                    }
                  >
                    <img
                      src={product.img}
                      alt={product.title}
                      className='size-24 object-cover'
                    />
                    <h3 className='text-xs font-bold'>{product.title}</h3>
                  </div>
                ))}
                <div className='snap-center shrink-0 w-1/6'></div>
              </div>
              <div className='absolute top-0 left-0 h-full w-[10%] bg-gradient-to-r from-white'></div>
              <div className='absolute top-0 right-0 h-full w-[10%] bg-gradient-to-l from-white'></div>
            </div>
          </div>
        </div>
      </dialog>
    </section>
  );
};

export default ProgramSection;
