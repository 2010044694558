import { useEffect, useState } from 'react';
import { ChevronDoubleRightIcon } from '@heroicons/react/20/solid';

const BMIBar = ({ BMI, color }) => {
  const BMIBarUI = [
    {
      state: 'Under',
      bgColor: '#ffc412',
      max: 18.5,
      range: 18.5,
    },
    {
      state: 'Normal',
      bgColor: '#13cdc4',
      max: 24.9,
      range: 6.4,
    },
    {
      state: 'Over',
      bgColor: '#1288a9',
      max: 29.9,
      range: 5,
    },
    {
      state: 'Obese',
      bgColor: '#eb4c68',
      max: 39.9,
      range: 10,
    },
  ];

  const BMIBarValue = [
    {
      value: 18.5,
    },
    {
      value: 24.9,
    },
    {
      value: 29.9,
    },
  ];

  return (
    <div className='w-full pt-8'>
      <div className='flex items-center justify-center w-full'>
        {BMIBarUI.map((stage, i) => (
          <div
            key={stage.state}
            className={`relative py-1 w-1/4 text-center text-sm ${
              i === 0 ? 'rounded-l-full' : i === 3 ? 'rounded-r-full' : ''
            }`}
            style={{
              backgroundColor: stage.bgColor,
            }}
          >
            <div className='text-white font-bold'>{stage.state}</div>
            {(i === 0 ? 0 : BMIBarUI[i - 1].max) < BMI && BMI <= stage.max && (
              <div
                className={`absolute transition duration-500 -top-7 z-10 font-bold text-xs -translate-x-1/2 flex flex-col items-center`}
                style={{
                  left:
                    i > 0
                      ? `${((BMI - BMIBarUI[i - 1].max) * 100) / stage.range}%`
                      : `${(BMI * 100) / stage.range}%`,
                  color: stage.bgColor,
                }}
              >
                <div>{BMI}</div>
                <div
                  className={`border-l-2 h-4`}
                  style={{
                    borderColor: stage.bgColor,
                  }}
                />
              </div>
            )}
            {i === BMIBarUI.length - 1 && stage.max < BMI && (
              <div
                className={`absolute transition duration-500 -top-8 z-10 font-bold text-xs flex flex-col items-center right-0 text-[#b53371]`}
              >
                <div>{BMI}</div>
                <ChevronDoubleRightIcon className='size-4' />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className='flex items-center justify-evenly w-full'>
        {BMIBarValue.map((stage) => (
          <div
            key={stage.value}
            className='text-center text-xs font-bold'
          >
            <div className=''>{stage.value}</div>
          </div>
        ))}
      </div>
      <div className='flex justify-center items-center gap-1 mt-1'>
        <div
          className={'size-3'}
          style={{
            backgroundColor: BMI > 0 ? color : '#ccc',
          }}
        />
        <div className='text-xs'>
          Your BMI is{' '}
          <span className='font-bold'>{BMI > 0 ? BMI : 'unknown'}</span>
        </div>
      </div>
    </div>
  );
};

export default BMIBar;
