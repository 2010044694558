import mainPhoto from '../assets/photos/main.png';

const HeroSection = () => {
  return (
    <section id='home'>
      <div
        style={{
          backgroundImage: `url(${mainPhoto})`,
          backgroundSize: 'cover',
          backgroundPosition: 'bottom',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className='text-white flex flex-col gap-4 h-[480px] sm:h-[640px]'>
          <div className='relative font-["Anton"] font-medium text-xl text-gray-400 pt-12 pb-4 sm:text-2xl'>
            <div className='px-3'>TheChamps</div>
            <div className='absolute bg-gradient-to-b from-[#626c39] h-full top-0 w-full'></div>
          </div>
          <div className='px-4 self-end sm:self-auto'>
            <h1 className='uppercase font-["Anton"] font-medium italic text-5xl mb-1 text-right w-48 sm:w-full sm:text-center'>
              unleash your inner champion
            </h1>
            <p className='font-["Roboto"] leading-tight font-medium italic text-sm w-48 text-right sm:text-center sm:w-full sm:text-xl'>
              Build lasting habits and achieve sustainable change.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
