import { useRef, useState } from 'react';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  XCircleIcon,
  ArrowPathIcon,
} from '@heroicons/react/20/solid';
import BMIBar from '../components/bmi';
import axios from 'axios';

const BMI = {
  UNDERWEIGHT: '18.5',
  HEALTHY_WEIGHT: '24.9',
  OVERWEIGHT: '29.9',
  OBESE: '39.9',
  EXTREMELY_OBESE: '40',
};

const ranges = [
  {
    color: '#ffc412',
    operator: '<',
    metric: '18.5',
    status: 'Underweight',
    key: BMI.UNDERWEIGHT,
  },
  {
    color: '#13cdc4',
    operator: '',
    metric: '18.5 ~ 24.9', // 6.4
    status: 'Healthy Weight',
    key: BMI.HEALTHY_WEIGHT,
  },
  {
    color: '#1288a9',
    operator: '',
    metric: '25 ~ 29.9', // 4.9
    status: 'Overweight',
    key: BMI.OVERWEIGHT,
  },
  {
    color: '#eb4c68',
    operator: '',
    metric: '30 ~ 39.9', // 9.9
    status: 'Obese',
    key: BMI.OBESE,
  },
  {
    color: '#b53371',
    operator: 'Over',
    metric: '40',
    status: 'Extremely Obese',
    key: BMI.EXTREMELY_OBESE,
  },
];

const ACTIVITY = {
  SEDENTARY: '1.2',
  LIGHTLY_ACTIVE: '1.375',
  MODERATELY_ACTIVE: '1.55',
  VERY_ACTIVE: '1.725',
  EXTRA_ACTIVE: '1.9',
};

const activities = [
  {
    key: 'sedentary',
    value: ACTIVITY.SEDENTARY,
    name: 'sedentary',
    label: 'None (little or no exercise)',
  },
  {
    key: 'lightlyActive',
    value: ACTIVITY.LIGHTLY_ACTIVE,
    name: 'lightlyActive',
    label: 'Light (1-4 days exercise or sports per week)',
  },
  {
    key: 'moderately',
    value: ACTIVITY.MODERATELY_ACTIVE,
    name: 'moderately',
    label: 'Some (5-7 days exercise or sports per week)',
  },
  {
    key: 'veryActive',
    value: ACTIVITY.VERY_ACTIVE,
    name: 'veryActive',
    label: 'Hard (everyday exercise or exercise 2 times per day)',
  },
  {
    key: 'extraActive',
    value: ACTIVITY.EXTRA_ACTIVE,
    name: 'extraActive',
    label: 'Extra Hard (exercise 2 or more times per day)',
  },
];

const BMRResult = ({ result }) => {
  return (
    <div className='px-2 py-1 bg-[#626c39] rounded-xl text-white text-sm space-y-1'>
      <div>
        <span className='font-semibold'>Your BMR is</span>{' '}
        <span className='font-extrabold text-[#e5eccc] tracking-wider text-xl'>
          {result.bmr.toFixed(1)}
        </span>{' '}
        Cal
      </div>
      <div>
        <span className='font-semibold'>
          Total Daily Energy Expenditure (TDEE) Calories per day
        </span>{' '}
        <span className='font-extrabold text-[#e5eccc] tracking-wider text-xl'>
          {result.calorie.toFixed(1)}
        </span>{' '}
        Cal
      </div>
      <div>
        <span className='font-semibold'>Proteins per day</span>{' '}
        <span className='font-extrabold text-[#e5eccc] tracking-wider text-xl'>
          {result.protein[0].toFixed(1)}
        </span>{' '}
        to{' '}
        <span className='font-extrabold text-[#e5eccc] tracking-wider text-xl'>
          {result.protein[1].toFixed(1)}
        </span>{' '}
        g
      </div>
    </div>
  );
};

const Result = ({ result, reset }) => {
  return (
    <div className=''>
      <div className='flex justify-between items-center'>
        <h1 className='font-bold'>Result</h1>
        <XCircleIcon
          className='size-4'
          onClick={reset}
        />
      </div>
      <div className='flex justify-center mb-2'>
        <div className='space-y-2'>
          <BMIBar
            BMI={Number(result.bmi.metric.toFixed(1))}
            color={result.bmi.color}
          />
          <BMRResult result={result} />
        </div>
      </div>
      <div className='mb-4'>
        <div className='border border-[#dd2476] rounded-xl p-2 bg-[#dd2476] bg-opacity-20 mb-2'>
          <div className='font-bold mb-2 flex gap-2 items-center'>
            <div>Want to Lose weight?</div>
            <ArrowDownIcon className='size-5 text-[#dd2476]' />
          </div>
          <div>
            <div className='flex justify-between'>
              <div>0.25 kg per week:</div>
              <div>
                <span className='font-bold text-[#dd2476] tracking-wider'>
                  {Math.floor(result.calorie - 250)}
                </span>{' '}
                ({Math.floor(((result.calorie - 250) * 100) / result.calorie)}
                %) Cal
              </div>
            </div>
            <div className='flex justify-between'>
              <div>0.5 kg per week:</div>
              <div>
                <span className='font-bold text-[#dd2476] tracking-wider'>
                  {Math.floor(result.calorie - 500)}
                </span>{' '}
                ({Math.floor(((result.calorie - 500) * 100) / result.calorie)}
                %) Cal
              </div>
            </div>
            <div className='flex justify-between'>
              <div>1 kg per week:</div>
              <div>
                <span className='font-bold text-[#dd2476] tracking-wider'>
                  {Math.floor(result.calorie - 1000)}
                </span>{' '}
                ({Math.floor(((result.calorie - 1000) * 100) / result.calorie)}
                %) Cal
              </div>
            </div>
          </div>
        </div>
        <div className='border border-sky-500 rounded-lg p-2 bg-sky-500 bg-opacity-20'>
          <div className='font-bold mb-2 flex gap-2 items-center'>
            <div>Want to Gain weight</div>
            <ArrowUpIcon className='size-5 text-sky-500' />
          </div>
          <div>
            <div className='flex justify-between'>
              <div>0.25 kg per week:</div>
              <div>
                <span className='font-bold text-sky-500 tracking-wider'>
                  {Math.floor(result.calorie + 250)}
                </span>{' '}
                ({Math.floor(((result.calorie + 250) * 100) / result.calorie)}
                %) Cal
              </div>
            </div>
            <div className='flex justify-between'>
              <div>0.5 kg per week:</div>
              <div>
                <span className='font-bold text-sky-500 tracking-wider'>
                  {Math.floor(result.calorie + 500)}
                </span>{' '}
                ({Math.floor(((result.calorie + 500) * 100) / result.calorie)}
                %) Cal
              </div>
            </div>
            <div className='flex justify-between'>
              <div>1 kg per week:</div>
              <div>
                <span className='font-bold text-sky-500 tracking-wider'>
                  {Math.floor(result.calorie + 1000)}
                </span>{' '}
                ({Math.floor(((result.calorie + 1000) * 100) / result.calorie)}
                %) Cal
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex justify-center items-center'>
        <button
          className='rounded-xl text-transparent py-2 bg-[#d00000] w-full font-bold bg-clip-text border border-[#d00000]'
          onClick={reset}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

const CalorieCalculationSection = () => {
  const resultRef = useRef(null);

  const [gender, setGender] = useState('female');
  const [activity, setActivity] = useState(ACTIVITY.SEDENTARY);
  const [age, setAge] = useState(0);
  const [height, setHeight] = useState(0);
  const [weight, setWeight] = useState(0);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({
    bmi: {
      color: '#ccc',
      metric: 0,
    },
    bmr: 0,
    calorie: 0,
    protein: [0, 0],
  });

  const calculateBMI = () => {
    const standard = [18.5, 24.9, 29.9, 39.9, 40];

    const cm = height;
    const kg = weight;

    if (cm && kg) {
      const result = kg / (cm / 100) ** 2;

      let position = 0;

      for (let i in standard) {
        if (result < standard[i]) {
          position = Number(i);
          break;
        }

        position = Number(i);
      }

      return {
        color: ranges[position].color,
        metric: result,
      };
    }

    alert('Invalid height or weight');

    return {
      color: '#ccc',
      metric: 0,
    };
  };

  const calculateBMR = () => {
    const cm = height;
    const kg = weight;

    if (cm && kg && age && age > 17) {
      let metric = 10 * kg + 6.25 * cm - 5 * age;

      if (gender === 'female') {
        metric = metric - 161;
      }

      if (gender === 'male') {
        metric = metric - 5;
      }

      return metric;
    }

    alert('Invalid height or weight or age');

    return 0;
  };

  const calculateCaloriePerDay = (bmr) => {
    return bmr * parseFloat(activity);
  };

  const calculateProteinPerDay = (bmi) => {
    const kg = weight;

    if (bmi <= 24.9) {
      if (activity === ACTIVITY.SEDENTARY) {
        return [kg * 1.2, kg * 1.8];
      }
      if (activity === ACTIVITY.LIGHTLY_ACTIVE) {
        return [kg * 1.2, kg * 1.8];
      }
      if (activity === ACTIVITY.MODERATELY_ACTIVE) {
        return [kg * 1.2, kg * 2.0];
      }
      if (activity === ACTIVITY.VERY_ACTIVE) {
        return [kg * 1.2, kg * 2.0];
      }
      if (activity === ACTIVITY.EXTRA_ACTIVE) {
        return [kg * 1.2, kg * 2.4];
      }
    }

    if (bmi > 24.9) {
      return [kg * 1.2, kg * 1.5];
    }

    return [0, 0];
  };

  const calculateOverall = async () => {
    try {
      setLoading(true);
      if (!email) {
        return alert('Email required');
      }

      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailRegex.test(email)) {
        return alert('Invalid email format');
      }

      const bmi = calculateBMI();
      const bmr = calculateBMR();

      let calorie = 0;
      let protein = [0, 0];

      if (bmr > 0) {
        calorie = calculateCaloriePerDay(bmr);
        protein = calculateProteinPerDay(bmi.metric);

        setResult({
          bmi,
          bmr,
          calorie,
          protein,
        });

        const path = 'https://api.dx2.link/marketing/subscribe';
        await axios.post(path, {
          email,
          gender,
          age,
          height,
          weight,
          activity,
        });

        openResultDialog();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const reset = () => {
    closeResultDialog();
    setEmail('');
    setGender('female');
    setActivity(ACTIVITY.SEDENTARY);
    setAge(0);
    setHeight(0);
    setWeight(0);
    setResult({
      bmi: {
        color: '#ccc',
        metric: 0,
      },
      bmr: 0,
      calorie: 0,
      protein: [0, 0],
    });
  };

  const openResultDialog = () => {
    resultRef.current.showModal();
    document.body.classList.add('overflow-hidden');
  };

  const closeResultDialog = () => {
    resultRef.current.close();
    document.body.classList.remove('overflow-hidden');
  };

  return (
    <section id='calculator'>
      <div className='bg-[#e5eccc] pb-6'>
        <div className='mb-2 text-3xl font-["Anton"] text-[#626c39] px-4 pt-6 sm:px-8'>
          Easy check your BMR
          <div className='text-sm'>(Basal Metabolic Rate)</div>
          and find out your weight loss zone
        </div>
        <div className='flex justify-center items-center'>
          <div className='rounded-xl px-3 py-8 bg-white my-4 max-w-lg mx-4 min-h-96'>
            <div className='mx-auto px-6'>
              <div className='text-xl text-center mb-6 font-bold uppercase'>
                Calorie Calculator
              </div>
              <div className='space-y-4'>
                <div className='flex justify-between items-center'>
                  <label className='w-1/3'>Gender</label>
                  <div className='w-2/3'>
                    <select
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      className='w-full border px-2 py-2 rounded-xl outline-none'
                    >
                      <option value='female'>Female</option>
                      <option value='male'>Male</option>
                    </select>
                  </div>
                </div>
                <div className='flex justify-between items-center'>
                  <label className='w-1/3'>Age</label>
                  <div className='w-2/3 flex justify-end items-center border px-3 py-2 rounded-xl gap-1'>
                    <input
                      type='number'
                      min={0}
                      max={100}
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      className='flex-1 outline-none'
                    />{' '}
                    <div className='opacity-40'>years</div>
                  </div>
                </div>
                <div className='flex justify-between items-center'>
                  <label className='w-1/3'>Height</label>
                  <div className='w-2/3 flex justify-end items-center border px-2 py-2 rounded-xl gap-1'>
                    <input
                      type='number'
                      min={0}
                      max={300}
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                      className='flex-1 outline-none'
                    />{' '}
                    <div className='opacity-40'>cm</div>
                  </div>
                </div>
                <div className='flex justify-between items-center'>
                  <label className='w-1/3'>Weight</label>
                  <div className='w-2/3 flex justify-end items-center border px-2 py-2 rounded-xl gap-1'>
                    <input
                      type='number'
                      min={0}
                      max={500}
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      className='flex-1 outline-none'
                    />{' '}
                    <div className='opacity-40'>kg</div>
                  </div>
                </div>
              </div>
              <div className='space-y-2 mt-4'>
                <div>
                  <label>Activity</label>
                </div>
                <select
                  value={activity}
                  onChange={(e) => setActivity(e.target.value)}
                  className='w-full outline-none  border px-2 py-2 rounded-xl'
                >
                  {activities.map((act) => (
                    <option
                      key={act.key}
                      value={act.value}
                    >
                      {act.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className='space-y-2 mt-4'>
                <div>
                  <label>Email</label>
                </div>
                <div className='w-full outline-none flex justify-end items-center border px-2 py-2 rounded-xl gap-1'>
                  <input
                    type='email'
                    min={0}
                    max={64}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className='flex-1 outline-none'
                    placeholder='Enter your email to get result'
                  />
                </div>
              </div>
              <div className='text-xs mt-4 text-center'>
                By clicking "Calculate" below you acknowledge that you may
                receive emails from <b>David Park</b> or our partners and can
                unsubscribe at any time.
              </div>
              <div className='flex justify-center items-center mt-4'>
                <button
                  className='rounded-xl text-white py-2 bg-[#d00000] w-full font-bold flex justify-center items-center'
                  onClick={calculateOverall}
                  disabled={loading}
                >
                  {loading ? (
                    <ArrowPathIcon className='size-6 animate-spin' />
                  ) : (
                    'Calculate'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <dialog
        ref={resultRef}
        className='p-4 rounded-xl'
        onCancel={reset}
      >
        {result.bmr > 0 && (
          <Result
            result={result}
            reset={reset}
          />
        )}
      </dialog>
    </section>
  );
};

export default CalorieCalculationSection;
