import { useState, useEffect, useRef } from 'react';
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  ChevronDoubleUpIcon,
} from '@heroicons/react/24/outline';
import { StarIcon } from '@heroicons/react/20/solid';
import reviewImg1 from '../assets/photos/review-1.png';
import reviewImg2 from '../assets/photos/review-2.png';
import reviewImg3 from '../assets/photos/review-3.png';
import reviewImg4 from '../assets/photos/review-4.png';
import reviewImg5 from '../assets/photos/review-5.png';
import reviewImg6 from '../assets/photos/review-6.png';
import reviewImg7 from '../assets/photos/review-7.png';
import reviewImg8 from '../assets/photos/review-8.png';
import reviewImg9 from '../assets/photos/review-9.png';
import reviewImg10 from '../assets/photos/review-10.png';
import reviews from '../assets/json/reviews.json';

const reviewImgs = [
  reviewImg1,
  reviewImg2,
  reviewImg3,
  reviewImg4,
  reviewImg5,
  reviewImg6,
  reviewImg7,
  reviewImg8,
  reviewImg9,
  reviewImg10,
];

const RateWithStars = ({ rate = 5 }) => {
  const [count, setCount] = useState([]);

  useEffect(() => {
    const arr = [];
    for (let i = 0; i < rate; i++) {
      arr.push(i);
    }
    setCount(arr);
  }, [rate]);

  return (
    <div className='flex items-center text-yellow-400'>
      {count.map((i) => (
        <StarIcon
          key={i}
          className='size-4'
        />
      ))}
    </div>
  );
};

const ReviewSection = () => {
  const horizontalRef = useRef(null);

  const handleNext = () => {
    horizontalRef.current.scrollBy({ left: 320, behavior: 'smooth' });
  };

  const handlePrev = () => {
    horizontalRef.current.scrollBy({ left: -320, behavior: 'smooth' });
  };

  return (
    <section
      id='review'
      className='bg-[#626c39]'
    >
      <div className='mb-6 text-3xl font-["Anton"] text-white px-4 pt-4 sm:px-8'>
        Before & After: Real Stories
      </div>
      <div className='relative mb-6'>
        <div
          ref={horizontalRef}
          className={`flex snap-x overflow-x-scroll scrollbar-hidden snap-mandatory max-w-xl mx-auto`}
        >
          {reviewImgs.map((review, i) => (
            <div
              key={i}
              className='snap-always snap-center w-full shrink-0 flex flex-col justify-start items-center px-4 sm:px-8'
            >
              <div className='rounded-xl border-[4px] border-[#e5eccc] overflow-hidden'>
                <img
                  src={review}
                  alt='review'
                  className='scale-[1.01]'
                />
              </div>
            </div>
          ))}
        </div>
        <div className='absolute top-1/2 text-[#e5eccc]'>
          <ChevronLeftIcon
            className='size-4 sm:size-8 sm:stroke-2'
            onClick={handlePrev}
          />
        </div>
        <div className='absolute top-1/2 right-0 text-[#e5eccc]'>
          <ChevronRightIcon
            className='size-4 sm:size-8 sm:stroke-2'
            onClick={handleNext}
          />
        </div>
      </div>
      <div className='mb-6 text-3xl font-["Anton"] text-white px-4 pt-4 sm:px-8'>
        Hear from 500+ Champs!
      </div>
      <div className='relative h-96 overflow-hidden max-w-xl mx-auto'>
        <div className='overflow-y-scroll h-full p-4 space-y-4 scrollbar-hidden'>
          <div className='h-4 flex justify-center'>
            <ChevronDoubleUpIcon className='size-4 text-[#e5eccc] animate-bounce' />
          </div>
          {reviews.map((review, i) => (
            <div
              key={i}
              className={`bg-white px-3 py-2 rounded-xl`}
            >
              <div className='flex items-center gap-2 mb-1'>
                <div className='font-bold opacity-75'>
                  {review.name.slice(0, 2)}***
                </div>
                <div className='flex items-center text-yellow-400'>
                  <RateWithStars rate={review.rate} />
                </div>
              </div>
              <div className='text-sm opacity-50'>{review.word}</div>
            </div>
          ))}
          <div className='h-4'></div>
        </div>
        <div className='absolute top-0 left-0 w-full h-[10%] bg-gradient-to-b from-[#626c39]'></div>
        <div className='absolute bottom-0 left-0 w-full h-[10%] bg-gradient-to-t from-[#626c39]'></div>
      </div>
    </section>
  );
};

export default ReviewSection;
