export const country = [
  {
    code: 'AF',
    unicode: 'U+1F1E6 U+1F1EB',
    name: 'Afghanistan',
    emoji: '🇦🇫',
    phoneCode: '93',
  },
  {
    code: 'AX',
    unicode: 'U+1F1E6 U+1F1FD',
    name: 'Åland Islands',
    phoneCode: '358 18',
    emoji: '🇦🇽',
  },
  {
    code: 'AL',
    unicode: 'U+1F1E6 U+1F1F1',
    name: 'Albania',
    emoji: '🇦🇱',
    phoneCode: '355',
  },
  {
    code: 'DZ',
    unicode: 'U+1F1E9 U+1F1FF',
    name: 'Algeria',
    emoji: '🇩🇿',
    phoneCode: '213',
  },
  {
    code: 'AS',
    unicode: 'U+1F1E6 U+1F1F8',
    name: 'American Samoa',
    emoji: '🇦🇸',
    phoneCode: '1-684',
  },
  {
    code: 'AD',
    unicode: 'U+1F1E6 U+1F1E9',
    name: 'Andorra',
    emoji: '🇦🇩',
    phoneCode: '376',
  },
  {
    code: 'AO',
    unicode: 'U+1F1E6 U+1F1F4',
    name: 'Angola',
    emoji: '🇦🇴',
    phoneCode: '244',
  },
  {
    code: 'AI',
    unicode: 'U+1F1E6 U+1F1EE',
    name: 'Anguilla',
    emoji: '🇦🇮',
    phoneCode: '1-264',
  },
  {
    code: 'AQ',
    unicode: 'U+1F1E6 U+1F1F6',
    name: 'Antarctica',
    emoji: '🇦🇶',
    phoneCode: '672',
  },
  {
    code: 'AG',
    unicode: 'U+1F1E6 U+1F1EC',
    name: 'Antigua & Barbuda',
    phoneCode: '1-268',
    emoji: '🇦🇬',
  },
  {
    code: 'AR',
    unicode: 'U+1F1E6 U+1F1F7',
    name: 'Argentina',
    emoji: '🇦🇷',
    phoneCode: '54',
  },
  {
    code: 'AM',
    unicode: 'U+1F1E6 U+1F1F2',
    name: 'Armenia',
    emoji: '🇦🇲',
    phoneCode: '374',
  },
  {
    code: 'AW',
    unicode: 'U+1F1E6 U+1F1FC',
    name: 'Aruba',
    emoji: '🇦🇼',
    phoneCode: '297',
  },
  {
    code: 'AC',
    unicode: 'U+1F1E6 U+1F1E8',
    name: 'Ascension Island',
    phoneCode: '247',
    emoji: '🇦🇨',
  },
  {
    code: 'AU',
    unicode: 'U+1F1E6 U+1F1FA',
    name: 'Australia',
    emoji: '🇦🇺',
    phoneCode: '61',
  },
  {
    code: 'AT',
    unicode: 'U+1F1E6 U+1F1F9',
    name: 'Austria',
    emoji: '🇦🇹',
    phoneCode: '43',
  },
  {
    code: 'AZ',
    unicode: 'U+1F1E6 U+1F1FF',
    name: 'Azerbaijan',
    emoji: '🇦🇿',
    phoneCode: '994',
  },
  {
    code: 'BS',
    unicode: 'U+1F1E7 U+1F1F8',
    name: 'Bahamas',
    emoji: '🇧🇸',
    phoneCode: '1-242',
  },
  {
    code: 'BH',
    unicode: 'U+1F1E7 U+1F1ED',
    name: 'Bahrain',
    emoji: '🇧🇭',
    phoneCode: '973',
  },
  {
    code: 'BD',
    unicode: 'U+1F1E7 U+1F1E9',
    name: 'Bangladesh',
    emoji: '🇧🇩',
    phoneCode: '880',
  },
  {
    code: 'BB',
    unicode: 'U+1F1E7 U+1F1E7',
    name: 'Barbados',
    emoji: '🇧🇧',
    phoneCode: '1-246',
  },
  {
    code: 'BY',
    unicode: 'U+1F1E7 U+1F1FE',
    name: 'Belarus',
    emoji: '🇧🇾',
    phoneCode: '375',
  },
  {
    code: 'BE',
    unicode: 'U+1F1E7 U+1F1EA',
    name: 'Belgium',
    emoji: '🇧🇪',
    phoneCode: '32',
  },
  {
    code: 'BZ',
    unicode: 'U+1F1E7 U+1F1FF',
    name: 'Belize',
    emoji: '🇧🇿',
    phoneCode: '501',
  },
  {
    code: 'BJ',
    unicode: 'U+1F1E7 U+1F1EF',
    name: 'Benin',
    emoji: '🇧🇯',
    phoneCode: '229',
  },
  {
    code: 'BM',
    unicode: 'U+1F1E7 U+1F1F2',
    name: 'Bermuda',
    emoji: '🇧🇲',
    phoneCode: '1-441',
  },
  {
    code: 'BT',
    unicode: 'U+1F1E7 U+1F1F9',
    name: 'Bhutan',
    emoji: '🇧🇹',
    phoneCode: '975',
  },
  {
    code: 'BO',
    unicode: 'U+1F1E7 U+1F1F4',
    name: 'Bolivia',
    emoji: '🇧🇴',
    phoneCode: '591',
  },
  {
    code: 'BA',
    unicode: 'U+1F1E7 U+1F1E6',
    name: 'Bosnia & Herzegovina',
    phoneCode: '387',
    emoji: '🇧🇦',
  },
  {
    code: 'BW',
    unicode: 'U+1F1E7 U+1F1FC',
    name: 'Botswana',
    emoji: '🇧🇼',
    phoneCode: '267',
  },
  {
    code: 'BV',
    unicode: 'U+1F1E7 U+1F1FB',
    name: 'Bouvet Island',
    phoneCode: '47',
    emoji: '🇧🇻',
  },
  {
    code: 'BR',
    unicode: 'U+1F1E7 U+1F1F7',
    name: 'Brazil',
    emoji: '🇧🇷',
    phoneCode: '55',
  },
  {
    code: 'IO',
    unicode: 'U+1F1EE U+1F1F4',
    name: 'British Indian Ocean Territory',
    emoji: '🇮🇴',
    phoneCode: '246',
  },
  {
    code: 'VG',
    unicode: 'U+1F1FB U+1F1EC',
    name: 'British Virgin Islands',
    emoji: '🇻🇬',
    phoneCode: '1-284',
  },
  {
    code: 'BN',
    unicode: 'U+1F1E7 U+1F1F3',
    name: 'Brunei',
    emoji: '🇧🇳',
    phoneCode: '673',
  },
  {
    code: 'BG',
    unicode: 'U+1F1E7 U+1F1EC',
    name: 'Bulgaria',
    emoji: '🇧🇬',
    phoneCode: '359',
  },
  {
    code: 'BF',
    unicode: 'U+1F1E7 U+1F1EB',
    name: 'Burkina Faso',
    emoji: '🇧🇫',
    phoneCode: '226',
  },
  {
    code: 'BI',
    unicode: 'U+1F1E7 U+1F1EE',
    name: 'Burundi',
    emoji: '🇧🇮',
    phoneCode: '257',
  },
  {
    code: 'KH',
    unicode: 'U+1F1F0 U+1F1ED',
    name: 'Cambodia',
    emoji: '🇰🇭',
    phoneCode: '855',
  },
  {
    code: 'CM',
    unicode: 'U+1F1E8 U+1F1F2',
    name: 'Cameroon',
    emoji: '🇨🇲',
    phoneCode: '237',
  },
  {
    code: 'CA',
    unicode: 'U+1F1E8 U+1F1E6',
    name: 'Canada',
    emoji: '🇨🇦',
    phoneCode: '1',
  },
  {
    code: 'CV',
    unicode: 'U+1F1E8 U+1F1FB',
    name: 'Cape Verde',
    emoji: '🇨🇻',
    phoneCode: '238',
  },
  {
    code: 'BQ',
    unicode: 'U+1F1E7 U+1F1F6',
    name: 'Caribbean Netherlands',
    phoneCode: '599',
    emoji: '🇧🇶',
  },
  {
    code: 'KY',
    unicode: 'U+1F1F0 U+1F1FE',
    name: 'Cayman Islands',
    emoji: '🇰🇾',
    phoneCode: '1-345',
  },
  {
    code: 'CF',
    unicode: 'U+1F1E8 U+1F1EB',
    name: 'Central African Republic',
    emoji: '🇨🇫',
    phoneCode: '236',
  },
  {
    code: 'TD',
    unicode: 'U+1F1F9 U+1F1E9',
    name: 'Chad',
    emoji: '🇹🇩',
    phoneCode: '235',
  },
  {
    code: 'CL',
    unicode: 'U+1F1E8 U+1F1F1',
    name: 'Chile',
    emoji: '🇨🇱',
    phoneCode: '56',
  },
  {
    code: 'CN',
    unicode: 'U+1F1E8 U+1F1F3',
    name: 'China',
    emoji: '🇨🇳',
    phoneCode: '86',
  },
  {
    code: 'CX',
    unicode: 'U+1F1E8 U+1F1FD',
    name: 'Christmas Island',
    emoji: '🇨🇽',
    phoneCode: '61',
  },
  {
    code: 'CC',
    unicode: 'U+1F1E8 U+1F1E8',
    name: 'Cocos (Keeling) Islands',
    phoneCode: '61',
    emoji: '🇨🇨',
  },
  {
    code: 'CO',
    unicode: 'U+1F1E8 U+1F1F4',
    name: 'Colombia',
    emoji: '🇨🇴',
    phoneCode: '57',
  },
  {
    code: 'KM',
    unicode: 'U+1F1F0 U+1F1F2',
    name: 'Comoros',
    emoji: '🇰🇲',
    phoneCode: '269',
  },
  {
    code: 'CG',
    unicode: 'U+1F1E8 U+1F1EC',
    name: 'Congo - Brazzaville',
    phoneCode: '242',
    emoji: '🇨🇬',
  },
  {
    code: 'CD',
    unicode: 'U+1F1E8 U+1F1E9',
    name: 'Congo - Kinshasa',
    phoneCode: '243 1',
    emoji: '🇨🇩',
  },
  {
    code: 'CK',
    unicode: 'U+1F1E8 U+1F1F0',
    name: 'Cook Islands',
    emoji: '🇨🇰',
    phoneCode: '682',
  },
  {
    code: 'CR',
    unicode: 'U+1F1E8 U+1F1F7',
    name: 'Costa Rica',
    emoji: '🇨🇷',
    phoneCode: '506',
  },
  {
    code: 'CI',
    unicode: 'U+1F1E8 U+1F1EE',
    name: "Côte d'Ivoire",
    phoneCode: '225',
    emoji: '🇨🇮',
  },
  {
    code: 'HR',
    unicode: 'U+1F1ED U+1F1F7',
    name: 'Croatia',
    emoji: '🇭🇷',
    phoneCode: '385',
  },
  {
    code: 'CU',
    unicode: 'U+1F1E8 U+1F1FA',
    name: 'Cuba',
    emoji: '🇨🇺',
    phoneCode: '53',
  },
  {
    code: 'CW',
    unicode: 'U+1F1E8 U+1F1FC',
    name: 'Curaçao',
    phoneCode: '599',
    emoji: '🇨🇼',
  },
  {
    code: 'CY',
    unicode: 'U+1F1E8 U+1F1FE',
    name: 'Cyprus',
    emoji: '🇨🇾',
    phoneCode: '357',
  },
  {
    code: 'CZ',
    unicode: 'U+1F1E8 U+1F1FF',
    name: 'Czechia',
    phoneCode: '420',
    emoji: '🇨🇿',
  },
  {
    code: 'DK',
    unicode: 'U+1F1E9 U+1F1F0',
    name: 'Denmark',
    emoji: '🇩🇰',
    phoneCode: '45',
  },
  {
    code: 'DG',
    unicode: 'U+1F1E9 U+1F1EC',
    name: 'Diego Garcia',
    phoneCode: '246',
    emoji: '🇩🇬',
  },
  {
    code: 'DJ',
    unicode: 'U+1F1E9 U+1F1EF',
    name: 'Djibouti',
    emoji: '🇩🇯',
    phoneCode: '253',
  },
  {
    code: 'DM',
    unicode: 'U+1F1E9 U+1F1F2',
    name: 'Dominica',
    emoji: '🇩🇲',
    phoneCode: '1-767',
  },
  {
    code: 'DO',
    unicode: 'U+1F1E9 U+1F1F4',
    name: 'Dominican Republic',
    emoji: '🇩🇴',
    phoneCode: '1-849',
  },
  {
    code: 'EC',
    unicode: 'U+1F1EA U+1F1E8',
    name: 'Ecuador',
    emoji: '🇪🇨',
    phoneCode: '593',
  },
  {
    code: 'EG',
    unicode: 'U+1F1EA U+1F1EC',
    name: 'Egypt',
    emoji: '🇪🇬',
    phoneCode: '20',
  },
  {
    code: 'SV',
    unicode: 'U+1F1F8 U+1F1FB',
    name: 'El Salvador',
    emoji: '🇸🇻',
    phoneCode: '503',
  },
  {
    code: 'GQ',
    unicode: 'U+1F1EC U+1F1F6',
    name: 'Equatorial Guinea',
    emoji: '🇬🇶',
    phoneCode: '240',
  },
  {
    code: 'ER',
    unicode: 'U+1F1EA U+1F1F7',
    name: 'Eritrea',
    emoji: '🇪🇷',
    phoneCode: '291',
  },
  {
    code: 'EE',
    unicode: 'U+1F1EA U+1F1EA',
    name: 'Estonia',
    emoji: '🇪🇪',
    phoneCode: '372',
  },
  {
    code: 'ET',
    unicode: 'U+1F1EA U+1F1F9',
    name: 'Ethiopia',
    emoji: '🇪🇹',
    phoneCode: '251',
  },
  {
    code: 'FK',
    unicode: 'U+1F1EB U+1F1F0',
    name: 'Falkland Islands',
    emoji: '🇫🇰',
    phoneCode: '500',
  },
  {
    code: 'FO',
    unicode: 'U+1F1EB U+1F1F4',
    name: 'Faroe Islands',
    emoji: '🇫🇴',
    phoneCode: '298',
  },
  {
    code: 'FJ',
    unicode: 'U+1F1EB U+1F1EF',
    name: 'Fiji',
    emoji: '🇫🇯',
    phoneCode: '679',
  },
  {
    code: 'FI',
    unicode: 'U+1F1EB U+1F1EE',
    name: 'Finland',
    emoji: '🇫🇮',
    phoneCode: '358',
  },
  {
    code: 'FR',
    unicode: 'U+1F1EB U+1F1F7',
    name: 'France',
    emoji: '🇫🇷',
    phoneCode: '33',
  },
  {
    code: 'PF',
    unicode: 'U+1F1F5 U+1F1EB',
    name: 'French Polynesia',
    emoji: '🇵🇫',
    phoneCode: '689',
  },
  {
    code: 'GA',
    unicode: 'U+1F1EC U+1F1E6',
    name: 'Gabon',
    emoji: '🇬🇦',
    phoneCode: '241',
  },
  {
    code: 'GM',
    unicode: 'U+1F1EC U+1F1F2',
    name: 'Gambia',
    emoji: '🇬🇲',
    phoneCode: '220',
  },
  {
    code: 'GE',
    unicode: 'U+1F1EC U+1F1EA',
    name: 'Georgia',
    emoji: '🇬🇪',
    phoneCode: '995',
  },
  {
    code: 'DE',
    unicode: 'U+1F1E9 U+1F1EA',
    name: 'Germany',
    emoji: '🇩🇪',
    phoneCode: '49',
  },
  {
    code: 'GH',
    unicode: 'U+1F1EC U+1F1ED',
    name: 'Ghana',
    emoji: '🇬🇭',
    phoneCode: '233',
  },
  {
    code: 'GI',
    unicode: 'U+1F1EC U+1F1EE',
    name: 'Gibraltar',
    emoji: '🇬🇮',
    phoneCode: '350',
  },
  {
    code: 'GR',
    unicode: 'U+1F1EC U+1F1F7',
    name: 'Greece',
    emoji: '🇬🇷',
    phoneCode: '30',
  },
  {
    code: 'GL',
    unicode: 'U+1F1EC U+1F1F1',
    name: 'Greenland',
    emoji: '🇬🇱',
    phoneCode: '299',
  },
  {
    code: 'GD',
    unicode: 'U+1F1EC U+1F1E9',
    name: 'Grenada',
    emoji: '🇬🇩',
    phoneCode: '1-473',
  },
  {
    code: 'GU',
    unicode: 'U+1F1EC U+1F1FA',
    name: 'Guam',
    emoji: '🇬🇺',
    phoneCode: '1-671',
  },
  {
    code: 'GT',
    unicode: 'U+1F1EC U+1F1F9',
    name: 'Guatemala',
    emoji: '🇬🇹',
    phoneCode: '502',
  },
  {
    code: 'GG',
    unicode: 'U+1F1EC U+1F1EC',
    name: 'Guernsey',
    emoji: '🇬🇬',
    phoneCode: '44-1481',
  },
  {
    code: 'GN',
    unicode: 'U+1F1EC U+1F1F3',
    name: 'Guinea',
    emoji: '🇬🇳',
    phoneCode: '240',
  },
  {
    code: 'GW',
    unicode: 'U+1F1EC U+1F1FC',
    name: 'Guinea-Bissau',
    emoji: '🇬🇼',
    phoneCode: '245',
  },
  {
    code: 'GY',
    unicode: 'U+1F1EC U+1F1FE',
    name: 'Guyana',
    emoji: '🇬🇾',
    phoneCode: '592',
  },
  {
    code: 'HT',
    unicode: 'U+1F1ED U+1F1F9',
    name: 'Haiti',
    emoji: '🇭🇹',
    phoneCode: '509',
  },
  {
    code: 'HN',
    unicode: 'U+1F1ED U+1F1F3',
    name: 'Honduras',
    emoji: '🇭🇳',
    phoneCode: '504',
  },
  {
    code: 'HU',
    unicode: 'U+1F1ED U+1F1FA',
    name: 'Hungary',
    emoji: '🇭🇺',
    phoneCode: '36',
  },
  {
    code: 'IS',
    unicode: 'U+1F1EE U+1F1F8',
    name: 'Iceland',
    emoji: '🇮🇸',
    phoneCode: '354',
  },
  {
    code: 'IN',
    unicode: 'U+1F1EE U+1F1F3',
    name: 'India',
    emoji: '🇮🇳',
    phoneCode: '246',
  },
  {
    code: 'ID',
    unicode: 'U+1F1EE U+1F1E9',
    name: 'Indonesia',
    emoji: '🇮🇩',
    phoneCode: '62',
  },
  {
    code: 'IR',
    unicode: 'U+1F1EE U+1F1F7',
    name: 'Iran',
    emoji: '🇮🇷',
    phoneCode: '98',
  },
  {
    code: 'IQ',
    unicode: 'U+1F1EE U+1F1F6',
    name: 'Iraq',
    emoji: '🇮🇶',
    phoneCode: '964',
  },
  {
    code: 'IE',
    unicode: 'U+1F1EE U+1F1EA',
    name: 'Ireland',
    emoji: '🇮🇪',
    phoneCode: '353',
  },
  {
    code: 'IM',
    unicode: 'U+1F1EE U+1F1F2',
    name: 'Isle of Man',
    emoji: '🇮🇲',
    phoneCode: '44-1624',
  },
  {
    code: 'IL',
    unicode: 'U+1F1EE U+1F1F1',
    name: 'Israel',
    emoji: '🇮🇱',
    phoneCode: '972',
  },
  {
    code: 'IT',
    unicode: 'U+1F1EE U+1F1F9',
    name: 'Italy',
    emoji: '🇮🇹',
    phoneCode: '39',
  },
  {
    code: 'JM',
    unicode: 'U+1F1EF U+1F1F2',
    name: 'Jamaica',
    emoji: '🇯🇲',
    phoneCode: '1-876',
  },
  {
    code: 'JP',
    unicode: 'U+1F1EF U+1F1F5',
    name: 'Japan',
    emoji: '🇯🇵',
    phoneCode: '81',
  },
  {
    code: 'JE',
    unicode: 'U+1F1EF U+1F1EA',
    name: 'Jersey',
    emoji: '🇯🇪',
    phoneCode: '44-1534',
  },
  {
    code: 'JO',
    unicode: 'U+1F1EF U+1F1F4',
    name: 'Jordan',
    emoji: '🇯🇴',
    phoneCode: '962',
  },
  {
    code: 'KZ',
    unicode: 'U+1F1F0 U+1F1FF',
    name: 'Kazakhstan',
    emoji: '🇰🇿',
    phoneCode: '7',
  },
  {
    code: 'KE',
    unicode: 'U+1F1F0 U+1F1EA',
    name: 'Kenya',
    emoji: '🇰🇪',
    phoneCode: '254',
  },
  {
    code: 'KI',
    unicode: 'U+1F1F0 U+1F1EE',
    name: 'Kiribati',
    emoji: '🇰🇮',
    phoneCode: '686',
  },
  {
    code: 'XK',
    unicode: 'U+1F1FD U+1F1F0',
    name: 'Kosovo',
    emoji: '🇽🇰',
    phoneCode: '383',
  },
  {
    code: 'KW',
    unicode: 'U+1F1F0 U+1F1FC',
    name: 'Kuwait',
    emoji: '🇰🇼',
    phoneCode: '965',
  },
  {
    code: 'KG',
    unicode: 'U+1F1F0 U+1F1EC',
    name: 'Kyrgyzstan',
    emoji: '🇰🇬',
    phoneCode: '996',
  },
  {
    code: 'LA',
    unicode: 'U+1F1F1 U+1F1E6',
    name: 'Laos',
    emoji: '🇱🇦',
    phoneCode: '856',
  },
  {
    code: 'LV',
    unicode: 'U+1F1F1 U+1F1FB',
    name: 'Latvia',
    emoji: '🇱🇻',
    phoneCode: '371',
  },
  {
    code: 'LB',
    unicode: 'U+1F1F1 U+1F1E7',
    name: 'Lebanon',
    emoji: '🇱🇧',
    phoneCode: '961',
  },
  {
    code: 'LS',
    unicode: 'U+1F1F1 U+1F1F8',
    name: 'Lesotho',
    emoji: '🇱🇸',
    phoneCode: '266',
  },
  {
    code: 'LR',
    unicode: 'U+1F1F1 U+1F1F7',
    name: 'Liberia',
    emoji: '🇱🇷',
    phoneCode: '231',
  },
  {
    code: 'LY',
    unicode: 'U+1F1F1 U+1F1FE',
    name: 'Libya',
    emoji: '🇱🇾',
    phoneCode: '218',
  },
  {
    code: 'LI',
    unicode: 'U+1F1F1 U+1F1EE',
    name: 'Liechtenstein',
    emoji: '🇱🇮',
    phoneCode: '423',
  },
  {
    code: 'LT',
    unicode: 'U+1F1F1 U+1F1F9',
    name: 'Lithuania',
    emoji: '🇱🇹',
    phoneCode: '370',
  },
  {
    code: 'LU',
    unicode: 'U+1F1F1 U+1F1FA',
    name: 'Luxembourg',
    emoji: '🇱🇺',
    phoneCode: '352',
  },
  {
    code: 'MK',
    unicode: 'U+1F1F2 U+1F1F0',
    name: 'Macedonia',
    emoji: '🇲🇰',
    phoneCode: '389',
  },
  {
    code: 'MG',
    unicode: 'U+1F1F2 U+1F1EC',
    name: 'Madagascar',
    emoji: '🇲🇬',
    phoneCode: '261',
  },
  {
    code: 'MW',
    unicode: 'U+1F1F2 U+1F1FC',
    name: 'Malawi',
    emoji: '🇲🇼',
    phoneCode: '265',
  },
  {
    code: 'MY',
    unicode: 'U+1F1F2 U+1F1FE',
    name: 'Malaysia',
    emoji: '🇲🇾',
    phoneCode: '60',
  },
  {
    code: 'MV',
    unicode: 'U+1F1F2 U+1F1FB',
    name: 'Maldives',
    emoji: '🇲🇻',
    phoneCode: '960',
  },
  {
    code: 'ML',
    unicode: 'U+1F1F2 U+1F1F1',
    name: 'Mali',
    emoji: '🇲🇱',
    phoneCode: '223',
  },
  {
    code: 'MT',
    unicode: 'U+1F1F2 U+1F1F9',
    name: 'Malta',
    emoji: '🇲🇹',
    phoneCode: '356',
  },
  {
    code: 'MH',
    unicode: 'U+1F1F2 U+1F1ED',
    name: 'Marshall Islands',
    emoji: '🇲🇭',
    phoneCode: '692',
  },
  {
    code: 'MR',
    unicode: 'U+1F1F2 U+1F1F7',
    name: 'Mauritania',
    emoji: '🇲🇷',
    phoneCode: '222',
  },
  {
    code: 'MU',
    unicode: 'U+1F1F2 U+1F1FA',
    name: 'Mauritius',
    emoji: '🇲🇺',
    phoneCode: '230',
  },
  {
    code: 'YT',
    unicode: 'U+1F1FE U+1F1F9',
    name: 'Mayotte',
    emoji: '🇾🇹',
    phoneCode: '262',
  },
  {
    code: 'MX',
    unicode: 'U+1F1F2 U+1F1FD',
    name: 'Mexico',
    emoji: '🇲🇽',
    phoneCode: '52',
  },
  {
    code: 'FM',
    unicode: 'U+1F1EB U+1F1F2',
    name: 'Micronesia',
    emoji: '🇫🇲',
    phoneCode: '691',
  },
  {
    code: 'MD',
    unicode: 'U+1F1F2 U+1F1E9',
    name: 'Moldova',
    emoji: '🇲🇩',
    phoneCode: '373',
  },
  {
    code: 'MC',
    unicode: 'U+1F1F2 U+1F1E8',
    name: 'Monaco',
    emoji: '🇲🇨',
    phoneCode: '377',
  },
  {
    code: 'MN',
    unicode: 'U+1F1F2 U+1F1F3',
    name: 'Mongolia',
    emoji: '🇲🇳',
    phoneCode: '976',
  },
  {
    code: 'ME',
    unicode: 'U+1F1F2 U+1F1EA',
    name: 'Montenegro',
    emoji: '🇲🇪',
    phoneCode: '382',
  },
  {
    code: 'MS',
    unicode: 'U+1F1F2 U+1F1F8',
    name: 'Montserrat',
    emoji: '🇲🇸',
    phoneCode: '1-664',
  },
  {
    code: 'MA',
    unicode: 'U+1F1F2 U+1F1E6',
    name: 'Morocco',
    emoji: '🇲🇦',
    phoneCode: '212',
  },
  {
    code: 'MZ',
    unicode: 'U+1F1F2 U+1F1FF',
    name: 'Mozambique',
    emoji: '🇲🇿',
    phoneCode: '258',
  },
  {
    code: 'NA',
    unicode: 'U+1F1F3 U+1F1E6',
    name: 'Namibia',
    emoji: '🇳🇦',
    phoneCode: '264',
  },
  {
    code: 'NR',
    unicode: 'U+1F1F3 U+1F1F7',
    name: 'Nauru',
    emoji: '🇳🇷',
    phoneCode: '674',
  },
  {
    code: 'NP',
    unicode: 'U+1F1F3 U+1F1F5',
    name: 'Nepal',
    emoji: '🇳🇵',
    phoneCode: '977',
  },
  {
    code: 'NL',
    unicode: 'U+1F1F3 U+1F1F1',
    name: 'Netherlands',
    emoji: '🇳🇱',
    phoneCode: '31',
  },
  {
    code: 'NC',
    unicode: 'U+1F1F3 U+1F1E8',
    name: 'New Caledonia',
    emoji: '🇳🇨',
    phoneCode: '687',
  },
  {
    code: 'NZ',
    unicode: 'U+1F1F3 U+1F1FF',
    name: 'New Zealand',
    emoji: '🇳🇿',
    phoneCode: '64',
  },
  {
    code: 'NI',
    unicode: 'U+1F1F3 U+1F1EE',
    name: 'Nicaragua',
    emoji: '🇳🇮',
    phoneCode: '505',
  },
  {
    code: 'NE',
    unicode: 'U+1F1F3 U+1F1EA',
    name: 'Niger',
    emoji: '🇳🇪',
    phoneCode: '227',
  },
  {
    code: 'NG',
    unicode: 'U+1F1F3 U+1F1EC',
    name: 'Nigeria',
    emoji: '🇳🇬',
    phoneCode: '234',
  },
  {
    code: 'NU',
    unicode: 'U+1F1F3 U+1F1FA',
    name: 'Niue',
    emoji: '🇳🇺',
    phoneCode: '683',
  },
  {
    code: 'KP',
    unicode: 'U+1F1F0 U+1F1F5',
    name: 'North Korea',
    emoji: '🇰🇵',
    phoneCode: '850',
  },
  {
    code: 'MP',
    unicode: 'U+1F1F2 U+1F1F5',
    name: 'Northern Mariana Islands',
    emoji: '🇲🇵',
    phoneCode: '1-670',
  },
  {
    code: 'NO',
    unicode: 'U+1F1F3 U+1F1F4',
    name: 'Norway',
    emoji: '🇳🇴',
    phoneCode: '47',
  },
  {
    code: 'OM',
    unicode: 'U+1F1F4 U+1F1F2',
    name: 'Oman',
    emoji: '🇴🇲',
    phoneCode: '968',
  },
  {
    code: 'PK',
    unicode: 'U+1F1F5 U+1F1F0',
    name: 'Pakistan',
    emoji: '🇵🇰',
    phoneCode: '92',
  },
  {
    code: 'PW',
    unicode: 'U+1F1F5 U+1F1FC',
    name: 'Palau',
    emoji: '🇵🇼',
    phoneCode: '680',
  },
  {
    code: 'PA',
    unicode: 'U+1F1F5 U+1F1E6',
    name: 'Panama',
    emoji: '🇵🇦',
    phoneCode: '507',
  },
  {
    code: 'PG',
    unicode: 'U+1F1F5 U+1F1EC',
    name: 'Papua New Guinea',
    emoji: '🇵🇬',
    phoneCode: '675',
  },
  {
    code: 'PY',
    unicode: 'U+1F1F5 U+1F1FE',
    name: 'Paraguay',
    emoji: '🇵🇾',
    phoneCode: '595',
  },
  {
    code: 'PE',
    unicode: 'U+1F1F5 U+1F1EA',
    name: 'Peru',
    emoji: '🇵🇪',
    phoneCode: '51',
  },
  {
    code: 'PH',
    unicode: 'U+1F1F5 U+1F1ED',
    name: 'Philippines',
    emoji: '🇵🇭',
    phoneCode: '63',
  },
  {
    code: 'PL',
    unicode: 'U+1F1F5 U+1F1F1',
    name: 'Poland',
    emoji: '🇵🇱',
    phoneCode: '48',
  },
  {
    code: 'PT',
    unicode: 'U+1F1F5 U+1F1F9',
    name: 'Portugal',
    emoji: '🇵🇹',
    phoneCode: '351',
  },
  {
    code: 'PR',
    unicode: 'U+1F1F5 U+1F1F7',
    name: 'Puerto Rico',
    emoji: '🇵🇷',
    phoneCode: '1-939',
  },
  {
    code: 'QA',
    unicode: 'U+1F1F6 U+1F1E6',
    name: 'Qatar',
    emoji: '🇶🇦',
    phoneCode: '974',
  },
  {
    code: 'RO',
    unicode: 'U+1F1F7 U+1F1F4',
    name: 'Romania',
    emoji: '🇷🇴',
    phoneCode: '40',
  },
  {
    code: 'RU',
    unicode: 'U+1F1F7 U+1F1FA',
    name: 'Russia',
    emoji: '🇷🇺',
    phoneCode: '7',
  },
  {
    code: 'RW',
    unicode: 'U+1F1F7 U+1F1FC',
    name: 'Rwanda',
    emoji: '🇷🇼',
    phoneCode: '250',
  },
  {
    code: 'WS',
    unicode: 'U+1F1FC U+1F1F8',
    name: 'Samoa',
    emoji: '🇼🇸',
    phoneCode: '1-684',
  },
  {
    code: 'SM',
    unicode: 'U+1F1F8 U+1F1F2',
    name: 'San Marino',
    emoji: '🇸🇲',
    phoneCode: '378',
  },
  {
    code: 'SA',
    unicode: 'U+1F1F8 U+1F1E6',
    name: 'Saudi Arabia',
    emoji: '🇸🇦',
    phoneCode: '966',
  },
  {
    code: 'SN',
    unicode: 'U+1F1F8 U+1F1F3',
    name: 'Senegal',
    emoji: '🇸🇳',
    phoneCode: '221',
  },
  {
    code: 'RS',
    unicode: 'U+1F1F7 U+1F1F8',
    name: 'Serbia',
    emoji: '🇷🇸',
    phoneCode: '381',
  },
  {
    code: 'SC',
    unicode: 'U+1F1F8 U+1F1E8',
    name: 'Seychelles',
    emoji: '🇸🇨',
    phoneCode: '248',
  },
  {
    code: 'SL',
    unicode: 'U+1F1F8 U+1F1F1',
    name: 'Sierra Leone',
    emoji: '🇸🇱',
    phoneCode: '232',
  },
  {
    code: 'SG',
    unicode: 'U+1F1F8 U+1F1EC',
    name: 'Singapore',
    emoji: '🇸🇬',
    phoneCode: '65',
  },
  {
    code: 'SX',
    unicode: 'U+1F1F8 U+1F1FD',
    name: 'Sint Maarten',
    emoji: '🇸🇽',
    phoneCode: '1-721',
  },
  {
    code: 'SK',
    unicode: 'U+1F1F8 U+1F1F0',
    name: 'Slovakia',
    emoji: '🇸🇰',
    phoneCode: '421',
  },
  {
    code: 'SI',
    unicode: 'U+1F1F8 U+1F1EE',
    name: 'Slovenia',
    emoji: '🇸🇮',
    phoneCode: '386',
  },
  {
    code: 'SB',
    unicode: 'U+1F1F8 U+1F1E7',
    name: 'Solomon Islands',
    emoji: '🇸🇧',
    phoneCode: '677',
  },
  {
    code: 'SO',
    unicode: 'U+1F1F8 U+1F1F4',
    name: 'Somalia',
    emoji: '🇸🇴',
    phoneCode: '252',
  },
  {
    code: 'ZA',
    unicode: 'U+1F1FF U+1F1E6',
    name: 'South Africa',
    emoji: '🇿🇦',
    phoneCode: '27',
  },
  {
    code: 'KR',
    unicode: 'U+1F1F0 U+1F1F7',
    name: 'South Korea',
    emoji: '🇰🇷',
    phoneCode: '82',
  },
  {
    code: 'SS',
    unicode: 'U+1F1F8 U+1F1F8',
    name: 'South Sudan',
    emoji: '🇸🇸',
    phoneCode: '211',
  },
  {
    code: 'ES',
    unicode: 'U+1F1EA U+1F1F8',
    name: 'Spain',
    emoji: '🇪🇸',
    phoneCode: '34',
  },
  {
    code: 'LK',
    unicode: 'U+1F1F1 U+1F1F0',
    name: 'Sri Lanka',
    emoji: '🇱🇰',
    phoneCode: '94',
  },
  {
    code: 'BL',
    unicode: 'U+1F1E7 U+1F1F1',
    name: 'St. Barthélemy',
    phoneCode: '590',
    emoji: '🇧🇱',
  },
  {
    code: 'SD',
    unicode: 'U+1F1F8 U+1F1E9',
    name: 'Sudan',
    emoji: '🇸🇩',
    phoneCode: '211',
  },
  {
    code: 'SR',
    unicode: 'U+1F1F8 U+1F1F7',
    name: 'Suriname',
    emoji: '🇸🇷',
    phoneCode: '597',
  },
  {
    code: 'SZ',
    unicode: 'U+1F1F8 U+1F1FF',
    name: 'Swaziland',
    emoji: '🇸🇿',
    phoneCode: '268',
  },
  {
    code: 'SE',
    unicode: 'U+1F1F8 U+1F1EA',
    name: 'Sweden',
    emoji: '🇸🇪',
    phoneCode: '46',
  },
  {
    code: 'CH',
    unicode: 'U+1F1E8 U+1F1ED',
    name: 'Switzerland',
    emoji: '🇨🇭',
    phoneCode: '41',
  },
  {
    code: 'SY',
    unicode: 'U+1F1F8 U+1F1FE',
    name: 'Syria',
    emoji: '🇸🇾',
    phoneCode: '963',
  },
  {
    code: 'TW',
    unicode: 'U+1F1F9 U+1F1FC',
    name: 'Taiwan',
    emoji: '🇹🇼',
    phoneCode: '886',
  },
  {
    code: 'TJ',
    unicode: 'U+1F1F9 U+1F1EF',
    name: 'Tajikistan',
    emoji: '🇹🇯',
    phoneCode: '992',
  },
  {
    code: 'TZ',
    unicode: 'U+1F1F9 U+1F1FF',
    name: 'Tanzania',
    emoji: '🇹🇿',
    phoneCode: '255',
  },
  {
    code: 'TH',
    unicode: 'U+1F1F9 U+1F1ED',
    name: 'Thailand',
    emoji: '🇹🇭',
    phoneCode: '66',
  },
  {
    code: 'TG',
    unicode: 'U+1F1F9 U+1F1EC',
    name: 'Togo',
    emoji: '🇹🇬',
    phoneCode: '228',
  },
  {
    code: 'TK',
    unicode: 'U+1F1F9 U+1F1F0',
    name: 'Tokelau',
    emoji: '🇹🇰',
    phoneCode: '690',
  },
  {
    code: 'TO',
    unicode: 'U+1F1F9 U+1F1F4',
    name: 'Tonga',
    emoji: '🇹🇴',
    phoneCode: '676',
  },
  {
    code: 'TN',
    unicode: 'U+1F1F9 U+1F1F3',
    name: 'Tunisia',
    emoji: '🇹🇳',
    phoneCode: '216',
  },
  {
    code: 'TR',
    unicode: 'U+1F1F9 U+1F1F7',
    name: 'Turkey',
    emoji: '🇹🇷',
    phoneCode: '90',
  },
  {
    code: 'TM',
    unicode: 'U+1F1F9 U+1F1F2',
    name: 'Turkmenistan',
    emoji: '🇹🇲',
    phoneCode: '993',
  },
  {
    code: 'TV',
    unicode: 'U+1F1F9 U+1F1FB',
    name: 'Tuvalu',
    emoji: '🇹🇻',
    phoneCode: '688',
  },
  {
    code: 'VI',
    unicode: 'U+1F1FB U+1F1EE',
    name: 'U.S. Virgin Islands',
    emoji: '🇻🇮',
    phoneCode: '1-340',
  },
  {
    code: 'UG',
    unicode: 'U+1F1FA U+1F1EC',
    name: 'Uganda',
    emoji: '🇺🇬',
    phoneCode: '256',
  },
  {
    code: 'UA',
    unicode: 'U+1F1FA U+1F1E6',
    name: 'Ukraine',
    emoji: '🇺🇦',
    phoneCode: '380',
  },
  {
    code: 'AE',
    unicode: 'U+1F1E6 U+1F1EA',
    name: 'United Arab Emirates',
    emoji: '🇦🇪',
    phoneCode: '971',
  },
  {
    code: 'GB',
    unicode: 'U+1F1EC U+1F1E7',
    name: 'United Kingdom',
    emoji: '🇬🇧',
    phoneCode: '44',
  },
  {
    code: 'US',
    unicode: 'U+1F1FA U+1F1F8',
    name: 'United States',
    emoji: '🇺🇸',
    phoneCode: '1',
  },
  {
    code: 'UY',
    unicode: 'U+1F1FA U+1F1FE',
    name: 'Uruguay',
    emoji: '🇺🇾',
    phoneCode: '598',
  },
  {
    code: 'UZ',
    unicode: 'U+1F1FA U+1F1FF',
    name: 'Uzbekistan',
    emoji: '🇺🇿',
    phoneCode: '998',
  },
  {
    code: 'VU',
    unicode: 'U+1F1FB U+1F1FA',
    name: 'Vanuatu',
    emoji: '🇻🇺',
    phoneCode: '678',
  },
  {
    code: 'VE',
    unicode: 'U+1F1FB U+1F1EA',
    name: 'Venezuela',
    emoji: '🇻🇪',
    phoneCode: '58',
  },
  {
    code: 'VN',
    unicode: 'U+1F1FB U+1F1F3',
    name: 'Vietnam',
    emoji: '🇻🇳',
    phoneCode: '84',
  },
  {
    code: 'EH',
    unicode: 'U+1F1EA U+1F1ED',
    name: 'Western Sahara',
    emoji: '🇪🇭',
    phoneCode: '212',
  },
  {
    code: 'YE',
    unicode: 'U+1F1FE U+1F1EA',
    name: 'Yemen',
    emoji: '🇾🇪',
    phoneCode: '967',
  },
  {
    code: 'ZM',
    unicode: 'U+1F1FF U+1F1F2',
    name: 'Zambia',
    emoji: '🇿🇲',
    phoneCode: '260',
  },
  {
    code: 'ZW',
    unicode: 'U+1F1FF U+1F1FC',
    name: 'Zimbabwe',
    emoji: '🇿🇼',
    phoneCode: '263',
  },
];
