import ReactPlayer from 'react-player';
import SmartPhone from '../components/ui/smartPhone';
import workoutPlanVid from '../assets/vids/workout_plan.gif';
import mealTrackerVid from '../assets/vids/meal_tracker.gif';
import workoutTrackerVid from '../assets/vids/workout_tracker.gif';

const Item = ({ vodUrl, description }) => {
  return (
    <div className='p-4 bg-white rounded-xl flex items-center justify-center gap-4 sm:gap-8'>
      <SmartPhone
        height={380}
        width={200}
      >
        <img
          src={vodUrl}
          alt='app'
          className='w-full h-full object-cover'
        />
        {/* <ReactPlayer
          url={vodUrl}
          width='100%'
          height='100%'
          muted={true}
          playing={true}
          loop={true}
          style={{
            backgroundColor: 'black',
          }}
        /> */}
      </SmartPhone>
      <div className='leading-none font-bold text-xl text-[#626c39] text-left sm:text-2xl'>
        {description.map((desc, i) => (
          <div key={i}>{desc}</div>
        ))}
      </div>
    </div>
  );
};

const AboutAppSection = () => {
  return (
    <section>
      <div className='p-4 bg-[#e5eccc] sm:p-8'>
        <div className='mb-4'>
          <h1 className='mb-2 text-3xl font-["Anton"] text-[#626c39]'>
            See what’s waiting inside...
          </h1>
          <p className='text-[#626c39]'>
            TheChamps offers tools to track and support every step of your
            fitness journey toward achieving your goals.
          </p>
        </div>
        <div className='space-y-4'>
          <Item
            vodUrl={workoutPlanVid}
            description={['Tailored', 'Weekly', 'Workout', 'Plan']}
          />
          <Item
            vodUrl={workoutTrackerVid}
            description={['Daily', 'Workout', 'Tracker']}
          />
          <Item
            vodUrl={mealTrackerVid}
            description={['Tailored', 'Meal Plan', '& Tracker']}
          />
          {/* <Item
            vodUrl={mealTrackerVid}
            description={['AI', 'Powered', 'Meal', 'Tracker']}
          /> */}
        </div>
      </div>
    </section>
  );
};

export default AboutAppSection;
