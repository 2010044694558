import HeroSection from './sections/hero';
import AboutSection from './sections/about';
import AboutNumberSection from './sections/aboutNumber';
import AboutPTSection from './sections/aboutPT';
import AboutAppSection from './sections/aboutApp';
import CalorieCalculationSection from './sections/calorieCalculation';
import ReviewSection from './sections/review';
import ApplicationSection from './sections/application';
import ProgramSection from './sections/program';
import FAQSection from './sections/faq';
import Footer from './sections/footer';
import Nav from './menu';

const App = () => {
  return (
    <div className='scroll-smooth max-w-3xl mx-auto'>
      <Nav />
      <HeroSection />
      <AboutSection />
      <AboutNumberSection />
      <AboutPTSection />
      <AboutAppSection />
      <CalorieCalculationSection />
      <ReviewSection />
      <ApplicationSection />
      <ProgramSection />
      <FAQSection />
      <Footer />
    </div>
  );
};

export default App;
