import {
  CheckBadgeIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import Form from '../components/application/form';

const reasons = [
  'Tailored Workouts Just for You',
  'Personalized Meal Planning',
  'Progress Check-Ins Every Week',
  'Train Anywhere, Anytime',
  'Routines That Fit Your Lifestyle',
  '1-on-1 Guidance and Support',
  'Track Your Goals with Ease',
  'Plans That Grow with You',
  'Pause Anytime, No Questions Asked',
];

const ApplicationSection = () => {
  return (
    <section id='application'>
      <div className='bg-[#e5eccc] pt-4 px-4 pb-12 sm:px-8'>
        <div className='mb-12'>
          <h1 className='mb-2 text-3xl font-["Anton"] text-[#626c39]'>
            Why David's Program
          </h1>
          <div className='hidden sm:flex justify-end gap-8 text-center text-[#626c39] font-bold mb-2'>
            <div>
              David's
              <br />
              Program
            </div>
            <div>
              Social Media
              <br />
              Workouts
            </div>
          </div>
          <div className='sm:flex sm:gap-4 sm:justify-between'>
            <div className='text-[#626c39]'>
              {reasons.map((reason, i) => (
                <div
                  key={i}
                  className='flex items-center justify-between font-semibold'
                >
                  <div>{reason}</div>
                  <div className='sm:hidden'>
                    <CheckBadgeIcon className='size-6' />
                  </div>
                </div>
              ))}
            </div>
            <div className='hidden sm:flex w-[214px] justify-between pr-1'>
              <div className='bg-[#626c39] rounded-xl px-11'>
                {reasons.map((reason, i) => (
                  <div
                    key={i}
                    className='text-white font-bold'
                  >
                    O
                  </div>
                ))}
              </div>
              <div className='px-9'>
                {reasons.map((reason, i) => (
                  <div
                    key={i}
                    className='text-[#626c39] font-bold'
                  >
                    {i === 3 || i === 8 ? 'O' : 'X'}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2 className='mb-4 text-4xl font-["Anton"] text-[#d00000] text-center uppercase tracking-widest'>
            ready to join?
          </h2>
          <Form />
        </div>
      </div>
    </section>
  );
};

export default ApplicationSection;
